export default {
    endpoints: {
        availabilitySummary: `search/availability/summary`,
    },
    headers: {
        'app-origin': 'marketplace',
        'accept': 'application/json, text/plain, */*',
        'app-device-uuid': '9bd9e1-7b8473-6c8143-8004a1-d8c1ae-2de732',
    },
    format: {
        isoDate: 'yyyy-MM-dd',
        short: 'iii, dd MMM yyyy',
        long: "iii, d MMM yyyy 'at' h:mm aaa",
    },
};
