import http from 'http.js';

import Store from 'core/stores/Store.js';
import {makeObservable, observable} from 'mobx';
import autobind from 'common/decorators/autobind.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import cookies from 'cookies.js';
export default class GrowthBookStore extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        this._currentRequest = null;

        makeObservable(this, {
            isLoaded: observable,
            experimentInfo: observable,
        });
        this.init();
    }

    isLoaded = false;
    experimentInfo = {};

    @autobind
    async init() {
        try {
            await this.fetchExperiments();
        } catch (error) {
            console.error('Error initializing GrowthBook');
        }
    }

    @autobind
    async fetchExperiments() {
        const url = '/api/core/v1/experiments/';
        if (this._currentRequest) {
            this._currentRequest.abort();
        }
        const request = http.get({url});
        this._currentRequest = request;

        const response = await this._currentRequest;

        if (response) {
            const cleanedKeys = {};
            for (const key of Object.keys(response)) {
                const newKey = key.replaceAll('-', '_');
                cleanedKeys[newKey] = response[key];
            }

            const experimentData = snakeToCamelObjectKeys(cleanedKeys);
            this.updateStore({
                isLoaded: true,
                experimentInfo: experimentData,
            });



            const event_data = {
                "growthbookExperiments":experimentData
            }

            const practiceUserId = cookies.get('practitioner')
   
            // practices_user_id
            if(practiceUserId){
                event_data["practice_user_id"] = practiceUserId
            }

            if(window.dataLayer){
                window.dataLayer.push({
                    ...event_data
                })
            }


            this._currentRequest = null;
        }
    }
}
